// profile search
.search-container.profile-search {
  position: relative;
  margin: auto 24px;

  input {
    min-width: 260px;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    list-style: none;
    padding: 4px 8px;

    &:hover {
      cursor: pointer;
      background-color: #e6f7ff;
    }

    div {
      padding: auto 6px;
      height: 32px;
      line-height: 1.5;
      display: flex;
      align-items: center;
    }

    .employee-icon {
      margin-right: 4px;
    }
  }

  .suggestion-container {
    position: absolute;
    z-index: 100;
    background-color: white;
    border: solid 1px #ddd;
    border-radius: 4px;
    margin-top: -4px;
    max-height: 200px;
    overflow: scroll;
  }

  .search-icon {
    display: none;
  }
}
