@import './../people-products-variables';

.notices {
  margin-bottom: 10px;
}

.release-note {
  display: flex;
  align-items: center;
  padding: 12px 15px;
  background: @yellow-1;
  border-radius: 4px;
  vertical-align: middle;
}

.release-note-update-text {
  height: 17px;
  width: 121px;
  color: #e34646;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  flex: auto;
  text-align: right;
}

.release-note-normal-text {
  height: 17px;
  width: 524px;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
}

.release-note-link-text {
  height: 17px;
  width: 524px;
  color: #4e6bde;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
}

.release-note-emoji {
  flex: 0 0 auto;
  margin-right: 10px;
  font-size: 24px;
  line-height: 1;
}
