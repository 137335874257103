@import '../../people-products-variables';

.organization-header {
  h1 {
    margin-bottom: 0;
  }

  p {
    font-size: @font-size-m;
    color: @gray-7;
  }

  .organization-header-options {
    display: flex;
    align-items: center;
    justify-content: stretch;
    padding: 5px;
    border-radius: 4px;
    background-color: #dee6ff;
    margin-bottom: 30px;

    > button.ant-btn.pp-button {
      min-width: auto;
      height: 32px;
      padding-left: 16px;
      padding-right: 16px;
      font-size: 14px;
      font-weight: 300;
    }

    > :not(:last-child) {
      margin-right: 20px;
    }

    > .flex-spacer {
      flex: 1 1 auto;
    }
  }
}
