// employee search
.search-container.member-search {
  margin: 15px 0;

  input {
    width: 200px;
  }

  .suggestion-container {
    position: relative;
    ul.search-suggestions {
      position: absolute;
      z-index: 100;

      list-style-type: none;
      background: #ffffff;
      min-width: 190px;
      margin: 0 5px;
      padding: 0;
      border: 1px solid #dee2e6;
      border-top-width: 0;
      border-radius: 0 0 6px 6px;

      > li {
        cursor: pointer;
        margin: 0;
        padding: 6px 8px;
        border-top: 1px solid #dee2e6;
        background-color: #fff;

        &.active,
        &:hover {
          background-color: #fffae6;
        }

        &:last-child {
          border-radius: 0 0 6px 6px;
        }

        &.search-suggestion-empty {
          cursor: default;
          background-color: #fefefe;
          &:hover {
            background-color: #fefefe;
          }
        }

        &.search-suggestion-error {
          cursor: default;
          background-color: #fefefe;
          &:hover {
            background-color: #fefefe;
          }
        }
      }
    }
  }
}
