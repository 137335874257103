.employee-table {
  .left-spacer {
    margin-left: 6px;
  }

  .employee-table-row {
    cursor: pointer;

    .id {
      height: 14px;
      width: 34px;
      color: #4a4a4a;
      font-family: Arial;
      font-size: 12px;
      letter-spacing: 0.1px;
      line-height: 14px;
    }

    .name {
      height: 20px;
      color: #353535;
      font-family: Arial;
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 0.14px;
      line-height: 21px;
    }
  }
}
