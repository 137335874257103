@import '../people-products-variables';

.iam-add-member-modal {
  .organization-member-search {
    margin-bottom: 10px;
  }
}

.iam-table {
  button {
    float: right;
    margin-bottom: 10px;
  }

  .add-member {
    display: flex;
    justify-content: flex-end;

    button {
      float: right;
      margin-bottom: 10px;
    }
  }

  .account-type {
    line-height: 100%;

    > .pp-icon,
    span {
      display: inline-block;
      vertical-align: middle;
      margin-left: 5px;
    }
  }

  .role {
    text-transform: capitalize;
    margin-bottom: 5px;

    span {
      text-transform: uppercase;
      display: inline-block;
      margin-left: 10px;
      font-size: 0.75em;
      color: @red-8;

      &:not(.group) {
        color: @blue-8;
      }
    }
  }
}

.iam-edit {
  .header-row {
    border-bottom: solid 1px #ccc;
    margin-bottom: 2.5vh;
  }

  .iam-edit-form {
    margin-bottom: 30px;

    .ant-form-item-control {
      line-height: 25px;
    }

    .iam-role-select {
      .iam-role-cascader {
        width: 270px;
        display: block;
        margin-right: 35px;
      }

      .iam-description {
        width: 270px;
        font-size: @font-size-xs;
        color: @gray-6;
        display: block;
        word-wrap: break-word;
        line-height: 20px;
      }

      .scope-options {
        margin: 0;

        .option-col {
          display: block;
          margin-right: 10px;
        }
      }

      .option-company {
        padding-left: 25px;
      }
    }

    .add-role {
      border: none;
    }
  }
}
