@import 'people-products-variables';

.pp-breadcrumb {
  display: block;
  list-style-type: none;
  margin: 0 0 15px 0;
  padding: 0;

  > li {
    display: inline;
    margin: 0;
    padding: 0;
    color: @gray-8;
    font-size: @font-size-s;

    &.pp-breadcrumb-opener > span {
      cursor: pointer;
    }

    &.pp-breadcrumb-separator {
      margin: 0 10px;
      color: @gray-6;
      font-size: @font-size-xs;
    }

    &:not(:last-child) > a {
      color: @gray-8;
    }

    &:last-child > a {
      font-weight: bold;
    }

    &:last-child > span {
      color: @gray-9;
      font-weight: bold;
    }
  }
}

.pp-tooltip.ant-tooltip {
  &.ant-tooltip-placement-bottom {
    margin-top: 0;
    padding-top: 0;
  }

  .ant-tooltip-arrow {
    display: none;
  }

  .ant-tooltip-inner {
    box-sizing: border-box;
    min-height: 19px;
    margin-top: 0;
    padding: 1px 5px 1px 5px;
    border-radius: 2px;
    background-color: @gray-8;
    color: white;
    font-size: @font-size-xs;
  }
}
