// This is a workaround to avoid build error.
// https://github.com/webpack-contrib/less-loader/issues/67#issuecomment-368339828
@import (css) url('https://fonts.googleapis.com/css?family=Roboto:400,700');
@import (css) url('https://use.typekit.net/rvw0kzf.css');
@import '~antd/dist/antd.less';
@import './variables';

#app-container {
  height: 100%;
}

@logoColor: #eee;

.header {
  .logo {
    margin-bottom: 0;
    color: @logoColor;
    font-weight: bold;
    text-decoration: none;

    a:link {
      color: @logoColor;
    }
    a:visited {
      color: @logoColor;
    }

    .logo-image {
      height: 22px;
    }
  }
}

// TODO: 定義をちゃんとする
// お試し用の設定
// companies table
.custom-filter-dropdown {
  padding: 8px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
}

.custom-filter-dropdown input {
  width: 130px;
  margin-right: 8px;
}

.highlight {
  color: #f50;
}

@import './common/organization/memberSelector';
@import './common/organization/organizationHeader';
@import './atoms/organization';
@import './atoms/employee';
@import './atoms/employeeSearch';
@import './atoms/profileSearch';
@import './atoms/organizationMemberSearch.less';
@import './atoms/notices.less';
@import './components/adminOrganizations';
@import './components/adminOrganizationChanges';
@import './components/companiesEmployeeEdit';
@import './components/companiesEmployees';
@import './components/companiesEmployee';
@import './components/login';
@import './components/dataExport';
@import './components/iam';
@import './people-products-navigations';
@import './people-products-buttons';
