@import '../people-products-variables';

.container-company-employee {
  h2 {
    color: #4a4a4a !important;
  }

  .employee-header {
    border-bottom: solid 1px #ccc;
    margin-bottom: 2.5vh;
  }

  .employee-description-header {
    margin: 0 0 20px 0;
    display: flex;

    .employee-description-icon {
      flex: 0 0 150px;
    }

    .employee-description-body {
      flex: 1 1 auto;

      .member-id {
        color: @gray-8;
        font-size: 14px;
        font-weight: normal;
      }

      .member-name {
        margin-bottom: 4px;
        color: @gray-8;
        font-size: 24px;
        font-weight: bold;
      }

      .member-contacts {
        color: @gray-8;
        font-size: 16px;
        font-weight: normal;
      }
    }
  }

  .employee-information-section {
    margin: 0 0 45px 0;

    h3 {
      display: flex;
      margin: 0 0 10px 0;
      padding: 0 10px 0 150px;
      color: @gray-8;
      font-size: 18px;
      font-weight: 500;

      .edit-button,
      .back-button {
        margin-left: auto;
      }

      .submit-button {
        margin-left: 15px;
      }
    }

    .employee-information-table {
      width: 100%;
    }

    .row-group:nth-child(even) {
      background-color: @gray-0;
    }

    .row {
      display: flex;

      &:not(:first-child) > .col {
        padding-top: 0;
      }
    }

    .col {
      flex: 0 0 auto;
      padding: 10px;
      color: @gray-8;
      font-size: 16px;
      font-weight: 500;

      &:first-child {
        padding-left: 150px;
      }

      &.col-heading {
        flex: 0 0 340px;
        color: @gray-7;
        font-weight: normal;
      }

      &.col-value {
        flex: 0 0 auto;
      }

      &.col-id {
        flex: 0 1 8em;
      }

      &.col-date {
        flex: 0 0 90px;
        text-align: right;
        white-space: nowrap;

        &.col-date-from {
          padding-right: 0;
        }

        &.col-date-to {
          padding-left: 0;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .container-company-employee {
    .employee-information-section {
      h3 {
        padding-left: 10px;
      }
      .col {
        &:first-child {
          padding-left: 10px;
        }

        &.col-heading {
          flex: 0 0 150px;
        }
      }
    }
  }
}
