.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 320px;
}

.teams-splash {
  margin-bottom: 20px;
  text-align: center;

  .teams-splash-image {
    width: 140px;
    height: 140px;
  }
}

.teams-sign-in-with-google {
  display: inline-block;
  width: 191px;
  height: 46px;
  text-indent: 100%;
  overflow: hidden;
  white-space: nowrap;
  background: url('/images/btn_google_signin_light_normal_web@2x.png') 0% 0% / cover transparent;

  &:active {
    background-image: url('/images/btn_google_signin_light_pressed_web@2x.png');
  }
}
