.container-admin-organizationChanges {
  .team-tree {
    margin: 16px 0;
  }
}

.admin-organization-changes-page {
  &.on-edit {
    padding-bottom: 50%;
  }
}

.organization-change-size-buttons {
  margin-bottom: 8px;
}

.organization-changes-unorganized-members {
  margin-bottom: 16px;

  .unorganized-members {
    border: solid 1px #ccc;
    border-bottom: none;

    .unorganized-member {
      border-bottom: solid 1px #ccc;
      padding: 4px;

      .employee-icon {
        width: 32px;
        border-radius: 16px;
      }

      .editor-team-member-name {
        font-weight: bold;
        margin: 0 4px;
      }
    }
  }
}

.organization-team-editor {
  display: flex;
  position: fixed;
  transition-duration: 0.5s;
  bottom: -20px;
  left: 0;
  width: 100%;
  overflow: none;
  height: 0;
  z-index: 1000;

  .organization-team-editor-sider {
    width: 200px;
  }
  .organization-team-editor-content {
    padding: 10px;
    flex: auto;
    background: #fff;
    overflow: scroll;
    transition-duration: 0.5s;
    margin: 0 24px;
  }

  &.on-edit {
    bottom: 0;
    height: 50%;

    .organization-team-editor-content {
      border-top: solid 1px #aaa;
      box-shadow: 0 -5px 3px -5px rgba(0, 0, 0, 0.4);

      .editor-header {
        .editor-team-name {
          //font-size: 1.4em;
        }
        .editor-team-menu {
        }
        .editor-team-close {
          text-align: right;
        }
      }

      .ant-row {
        margin-top: 16px;
      }
    }

    .editor-team-meta {
      .editor-team-parent {
        .ant-cascader-picker {
          width: 100%;
        }
      }
    }

    .editor-team-members {
      margin-top: 16px;

      .editor-team-leader {
        color: #ffa500;
      }
      .editor-team-header {
        border-bottom: solid 1px #ccc;

        > div {
          font-weight: bold;
          padding: 4px;
        }
      }

      .editor-team-members {
        > div {
          padding: 4px;
        }
        .ant-row {
          margin-top: 4px;
        }

        .editor-team-nonprimary {
          color: #999;
        }

        .editor-team-member {
          .employee-icon {
            width: 32px;
            border-radius: 16px;
          }

          .editor-team-member-name {
            font-weight: bold;
            margin: 0 4px;
          }
        }
      }
    }
    .editor-team-deangelous-operations {
      .editor-team-delete-button {
        text-align: right;
      }
    }
  }
}

.editor-member-add-other-teams {
  border: solid 3px #999;
  padding: 8px;
  margin-bottom: 8px;

  .ant-list-item-meta-title {
    font-weight: bold;
  }
}

.org-changes-table-operations {
  margin-bottom: 2vh;
  display: flex;
  justify-content: flex-end;

  button {
    float: right;
  }
}

.organization-order-team .ant-table-tbody > tr > td {
  padding: 3px 1px;

  .team-member-count:hover {
    cursor: default;
    background-color: transparent;
  }
}

.organization-order-team-icon {
  width: 18px;
  height: 18px;
}
