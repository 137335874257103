.organization-team {
  border-bottom: solid 1px #ccc;
  margin-bottom: 10px;

  cursor: initial;

  &.organization-team-parent:not(.organization-team-root) {
    cursor: pointer;
  }

  .organization-team {
    &:hover {
      background: rgba(242, 213, 138, 0.1);
    }

    &.on-edit,
    &.on-edit:hover {
      background: rgba(242, 213, 138, 1);
    }
  }

  .ant-list-item-content {
  }

  .organization-team-header {
    display: flex;
    //position: sticky;
    //top: 0;
    transition-duration: 0.5s;
    margin-bottom: 8px;

    // 1st element
    .organization-team-open {
      height: 20px;
      width: 20px;
      margin-right: 4px;

      .toggle-button {
        border-radius: 4px;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.1s linear;

        &:hover {
          background-color: rgba(3, 3, 3, 0.1);
        }
      }

      .toggle-button.root {
        cursor: initial;

        &:hover {
          background-color: white;
        }
      }
    }

    // 2nd element
    .organization-team-header-objects {
      flex: auto;
      display: flex;

      &.will-be-closed {
        color: #aaa;
      }
    }

    .organization-team-header-objects > a {
      flex: auto;
      display: flex;
      color: @text-color;
    }
  }

  // last element
  .organization-team-operation {
    &.on-edit {
    }

    align-self: last baseline;
  }

  // objecsts

  &.organization-team-header-small {
    .organization-team-name {
      font-size: 14px;
      font-weight: 500;
    }
  }

  &.organization-team-header-medium {
    .organization-team-name {
      font-size: 16px;
      font-weight: 500;
    }
  }

  .organization-team-meta,
  .organization-team-leader,
  .organization-team-operation {
    margin-left: 8px;
  }

  .organization-team-leader {
  }

  &.section-Group {
    > .organization-team-children {
      border-left-color: @section-group-bg;
    }
  }

  &.section-Company {
    > .organization-team-children {
      border-left-color: @section-company-bg;
    }
  }

  &.section-Division {
    > .organization-team-children {
      border-left-color: @section-division-bg;
    }
  }

  &.section-Department {
    > .organization-team-children {
      border-left-color: @section-department-bg;
    }
  }

  &.section-Team {
    > .organization-team-children {
      border-left-color: @section-team-bg;
    }
  }

  &.section-Unit {
    > .organization-team-children {
      border-left-color: @section-unit-bg;
    }
  }
}

.organization-team-leader {
}

.organization-team-members {
  display: flex;
  flex-flow: row wrap;
  padding-left: 22px;

  .member-card {
    font-size: 14px;
    margin: 0 4px 4px 0;
    padding: 4px;
    width: 160px;
    height: 40px;

    &.member-card-small {
      width: auto;
      height: auto;
    }

    .member-avatar {
      display: inline-block;
      margin-right: 4px;

      .employee-icon {
        width: 24px;
        border-radius: 12px;
      }
    }

    .member-profile {
      word-wrap: break-word;
      max-width: 122px;
      max-height: 35px;
      overflow: hidden;
      line-height: 1.1em;
    }
  }
}

.organization-team-children {
  padding-left: 30px;
  border-left: solid 2px #ccc;

  > .organization-teams > .organization-team:last-child {
    border-bottom: none;
  }
}

.organization-team-close {
  color: #fff;
  background: #f00;
  padding: 1px 4px;
  border-radius: 4px;
  margin-right: 8px;
  font-weight: bold;
}
