.member-selector {
  position: relative;

  .member-selector-suggest {
    position: absolute;
    z-index: 10; // FIXME: how can we decide z-index?
    width: 300px; // FIXME: auto にしたい
    max-height: 200px;
    overflow: scroll;
    padding: 1px;
    background: #fff;
    border: solid 1px #ccc;
    border-top: none;
    box-shadow: 0 1px 4px rgba(0,0,0,0.2);

    .member-selector-member {
      border-bottom: solid 1px #ccc;
      padding: 4px 8px;

      &:hover {
        background: rgba(242,213,138,0.1);
      }

      &.active {
        font-weight: bold;
        background: #e6f7ff;
      }
    }
  }
}
