.employee-icon {
  width: 54px;
  border-radius: 27px;

  &.size-xs {
    width: 12px;
  }

  &.size-sm {
    width: 24px;
  }

  &.size-md {
    width: 100px !important;
  }

  &.size-lg {
    width: 120px !important;
    border-radius: 60px !important;
  }

  &.size-list {
    width: 44px;
  }
}

.employee-left-spacer {
  margin-left: 4px;
}

.company-icon {
  height: 24px;
  width: 24px;
  margin-right: 4px;
}

.tag-search-suggestion {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 8px 1px 8px;
  gap: 24px;
  height: 22px;
  background: #5e7df6;
  border-radius: 13px;
  font-weight: 400;
  flex: none;
  order: 2;
  color: #ffffff;
  flex-grow: 0;
  width: fit-content;
  min-height: 0px !important;
}
