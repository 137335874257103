// Organization/Member
.member-card {
  border: solid 1px #ccc;
  border-radius: 2px;
  padding: 8px;
  width: 240px;
  height: 60px;
  display: inline-block;

  .member-profiles {
    display: flex;
    flex-flow: wrap row;
  }

  //&.member-card-medium => is default
  .member-profiles {
    .member-avatar {
      display: inline-block;
      margin-right: 4px;

      .employee-icon {
        width: 40px;
        border: none;
        border-radius: 20px;
      }
    }

    .member-profile {
      display: inline-block;

      .member-name {
        font-weight: 500;
      }

      .member-contacts,
      .member-role {
        font-size: 0.9em;
        color: #999;
      }
    }
  }

  &.member-card-small {
    padding: 0px;
    border: none;
    height: auto;
    width: auto;

    .member-profiles {
      .member-avatar {
        display: inline-block;
        margin-right: 4px;

        .employee-icon {
          width: 20px;
          border: none;
          border-radius: 10px;
        }
      }

      .member-profile {
        display: inline-block;

        .member-name {
          font-weight: 500;
        }

        .member-contacts,
        .member-role {
          display: none;
        }
      }
    }
  }

  a {
    color: inherit;
    display: inline-block;
    transition: all 0.1s linear;
  }

  a:hover {
    background-color: rgba(3, 3, 3, 0.1);
    border-radius: 4px;
  }
}

// Organization/TeamSectionLabel
.team-section-label {
  display: inline-block;
  padding: 1px 5px 2px;
  margin-right: 8px;
  border-radius: 4px;
  white-space: nowrap;
  font-size: 13px;

  .team-section-head-count {
    margin-left: 5px;
    padding-left: 5px;
    border-left: #fff solid 1px;
    font-size: 12px;

    > div {
      display: inline-block;
    }
  }

  &.labels-group {
    background-color: @section-group-bg;
    color: @section-group-color;

    .team-section-head-count {
      border-left-color: @section-group-color;

      > div svg g.fillable {
        fill: @section-group-color;
      }
    }
  }

  &.labels-company {
    background-color: @section-company-bg;
    color: @section-company-color;

    .team-section-head-count {
      border-left-color: @section-company-color;

      > div svg g.fillable {
        fill: @section-company-color;
      }
    }
  }

  &.labels-division {
    background-color: @section-division-bg;
    color: @section-division-color;

    .team-section-head-count {
      border-left-color: @section-division-color;

      > div svg g.fillable {
        fill: @section-division-color;
      }
    }
  }

  &.labels-department {
    background-color: @section-department-bg;
    color: @section-department-color;

    .team-section-head-count {
      border-left-color: @section-department-color;

      > div svg g.fillable {
        fill: @section-department-color;
      }
    }
  }

  &.labels-team {
    background-color: @section-team-bg;
    color: @section-team-color;

    .team-section-head-count {
      border-left-color: @section-team-color;

      > div svg g.fillable {
        fill: @section-team-color;
      }
    }
  }

  &.labels-unit {
    background-color: @section-unit-bg;
    color: @section-unit-color;

    .team-section-head-count {
      border-left-color: @section-unit-color;

      > div svg g.fillable {
        fill: @section-unit-color;
      }
    }
  }
}

// Organization/TeamMemberCount
.team-member-count {
  display: flex;
  align-items: center;
  font-size: 0.9em;
  padding: 4px;
  border-radius: 4px;
  transition: all 0.1s linear;

  &:hover {
    cursor: pointer;
    background-color: rgba(3, 3, 3, 0.1);
  }
}
