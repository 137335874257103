@import '../people-products-variables';

.data-export {
  h2 {
    font-siza: @font-size-l;
  }

  .container-content {
    margin-bottom: 20px;
    padding: 20px;
    background-color: rgba(85, 114, 237, 0.05);

    h3 {
      font-size: @font-size-m;
      font-weight: 800;
    }

    .description {
      margin-bottom: 20px;
      font-size: @font-size-xs;

      .about {
        margin-bottom: 15px;
      }

      .exported-data,
      .authorized-person {
        margin-bottom: 5px;
      }

      .key {
        font-weight: bold;
      }
    }

    .date-picker {
      margin-right: 10px;
    }

    button {
      background-color: @primary-color;
      color: @text-light-color;

      &:hover {
        background: @blue-5;
      }
    }

    .ant-alert {
      margin-bottom: 10px;
    }

    .date-picker-range {
      display: inline-block;
      margin-right: 10px;

      .separator {
        margin: 0px 5px !important;
      }
    }
  }
}
