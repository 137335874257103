@import '../people-products-variables';

.company-employee-edit {
  .employee-information-edit-group {
    margin: 10px 0 10px 150px;
    padding: 10px;
    background-color: @gray-0;
    border-radius: 2px;

    label {
      color: @gray-9;
      font-size: 14px;
    }

    .ant-input-group > .ant-col-4:not(:first-child),
    .ant-input-group > .ant-col-6:not(:first-child),
    .ant-input-group > .ant-col-7:not(:first-child),
    .ant-input-group > .ant-col-8:not(:first-child) {
      label {
        font-size: 12px;
      }
    }

    .ant-input-group > .ant-col-4:not(:last-child),
    .ant-input-group > .ant-col-6:not(:last-child),
    .ant-input-group > .ant-col-7:not(:last-child),
    .ant-input-group > .ant-col-8:not(:last-child),
    .ant-input-group > .ant-col-10:not(:last-child) {
      .ant-form-item {
        padding-right: 15px;
      }
    }
  }

  .employee-information-edit-action {
    margin: 10px 0 10px 150px;
    padding: 0;
  }

  .employee-information-edit-historical {
    &:not(:last-child) {
      margin-bottom: 20px;
    }

    > label {
      display: block;
      margin-bottom: 10px;
      font-size: 14px;
    }

    .ant-form-item {
      margin-bottom: 0;

      .ant-calendar-picker,
      .ant-cascader-picker,
      .ant-select {
        width: 100%;
      }
    }
  }

  .section-content:not(:last-child) {
    margin-bottom: 25px;
  }

  .row-name-notation-ja {
    .ant-form-item {
      margin-bottom: 8px;
    }
  }

  .col-admin-note {
    padding-right: 23px; // 15px (our input-group > col > form-item) + 8px (ant input-group > col)
    margin-bottom: 20px;
  }

  .ant-input.changed {
    background-color: @blue-1;
  }

  .input-date.changed {
    .ant-input {
      background-color: @blue-1;
    }
  }

  .form-item-label-required {
    height: 18px;
    margin: 0 5px;
    padding: 2px 9px;
    background-color: @red-7;
    border-radius: 9px;
    color: white;
    font-size: 12px;
    line-height: 1;
  }
}

@media only screen and (max-width: 768px) {
  .company-employee-edit {
    .employee-information-edit-group {
      margin-left: 10px;
    }

    .employee-information-edit-action {
      margin-left: 10px;
    }
  }
}
