// organization member search
.search-container.organization-search {
  position: relative;
  width: 300px;

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    list-style: none;
    padding: 4px 8px;

    &:hover {
      cursor: pointer;
      background-color: #e6f7ff;
    }

    &.search-suggestion-empty:hover {
      cursor: initial;
      background-color: initial;
    }

    div {
      display: flex;
      min-height: 32px;
      line-height: 1.5;
      align-items: center;
    }

    .employee-icon {
      margin-right: 4px;
    }
  }

  .suggestion-container {
    position: absolute;
    z-index: 100;
    margin-top: 5px;
    background-color: white;
    border: solid 1px #ddd;
    border-radius: 4px;
    max-height: 300px;
    overflow-x: hidden;
    overflow-y: scroll;
    box-sizing: border-box;
    width: 100%;
    border: 1px solid #9299a0;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .suggestion-container:empty {
    display: none;
  }

  .search-icon {
    margin-left: -7px;
    margin-top: 3px;
  }
}

.search-container.organization-member-search {
  position: relative;
  width: 300px;

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    list-style: none;
    padding: 4px 8px;

    &:hover {
      cursor: pointer;
      background-color: #e6f7ff;
    }

    &.search-suggestion-empty:hover {
      cursor: initial;
      background-color: initial;
    }

    div {
      display: flex;
      min-height: 32px;
      line-height: 1.5;
      align-items: center;
    }

    .employee-icon {
      margin-right: 4px;
    }
  }

  .suggestion-container {
    position: absolute;
    z-index: 100;
    margin-top: 5px;
    background-color: white;
    border: solid 1px #ddd;
    border-radius: 4px;
    max-height: 300px;
    overflow-x: hidden;
    overflow-y: scroll;
    box-sizing: border-box;
    width: 300px;
    border: 1px solid #9299a0;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .suggestion-container:empty {
    display: none;
  }
}

// for tags to be on one line
.search-suggestions-tags {
  display: flex;
  overflow: scroll;
  padding-bottom: 10px;
}

.slack-searchbox-icon {
  margin-left: 5px;
  margin-right: 5px;
}

.slack-searchbox-text {
  color: #4f6bdf;
  margin-left: 5px;
}

.search-suggestion-tag-container {
  width: auto;
  margin: -3px;
}
